<template>
  <cw-page
    icon="account_box"
    class="cw-business-loan-applicant"
  >
    <template #hero-title>
      <translate>
        Guarantor information
      </translate>
    </template>
    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div
            class="
              cw-border-container
              cw-border-container--primary
            "
          >
            <p>
              <translate>
                A personal guarantee is required for a SME loan. The executor of the loan
                application acts as a guarantor and is responsible for managing the loan.
              </translate>
              <a
                id="cw-business-loan-applicant--info"
                class="ml-1"
                href="#"
                @click.prevent.stop="
                  $bus.$emit('OPEN_CONFIRMATION_DIALOG', {
                    component: 'DialogGuarantor',
                    folder: 'fi',
                  });
                  $eventLogger.clickEvent($event);
                "
              >
                <translate>More information</translate>
              </a>
            </p>

            <p>
              <translate>
                Fill in the missing information and agree to be a guarantor of the loan so you can
                move forward with the loan application.
              </translate>
            </p>

            <p class="ma-0">
              <translate>
                The following information was found based on your identification.
              </translate>
            </p>

            <div class="cw-application__table mt-6">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <translate>Name</translate>
                    </td>
                    <td class="pl-0 text-right">
                      <b>{{ populationData.fullName }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td><translate>Identity number</translate></td>
                    <td class="pl-0 text-right">
                      <b>{{ populationData.ssn }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-top">
                      <translate>Address</translate>
                    </td>
                    <td class="pl-0 text-right">
                      <b>{{ populationData.streetAddress }}</b><br>
                      <b>{{ populationData.zip }} {{ populationData.city }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-row>
            <v-col
              class="
                px-0
                pr-sm-6
                pr-md-0
              "
              cols="12"
              sm="6"
              md="12"
            >
              <cw-text-field
                id="email"
                v-model="form.email"
                :hint="emailHint"
                :label="emailLabel"
                rules="required|email"
                type="text"
                anonymize
              />
            </v-col>

            <v-col
              class="
                px-0
                pl-sm-6
                pl-md-0
              "
              cols="12"
              sm="6"
              md="12"
            >
              <cw-text-field
                id="phone"
                v-model="form.phone"
                :hint="phoneHint"
                :label="phoneLabel"
                rules="required|phone"
                type="text"
                anonymize
              />
            </v-col>

            <v-col
              class="
                px-0
                pr-sm-6
                pr-md-0
              "
              cols="12"
              sm="6"
              md="12"
            >
              <cw-text-field
                id="assetsTotal"
                v-model="form.assetsTotal"
                :hint="assetsTotalHint"
                :label="assetsTotalLabel"
                lang="en-150"
                rules="required|notNegativeNumber"
                type="number"
                currency-input
              />
            </v-col>

            <v-col
              class="
                px-0
                pl-sm-6
                pl-md-0
              "
              cols="12"
              sm="6"
              md="12"
            >
              <cw-select
                id="education"
                v-model="form.education"
                :items="items.education"
                :hint="educationHint"
                :label="educationLabel"
                rules="required"
              />
            </v-col>

            <v-col
              class="
                px-0
                pl-sm-6
                pl-md-0
              "
              cols="12"
              sm="6"
              md="12"
            >
              <cw-select
                id="personalIncome"
                v-model="form.personalIncome"
                :items="items.income"
                :label="personalIncomeLabel"
                :hint="personalIncomeHint"
                rules="required|notNegativeNumber"
              />
            </v-col>

            <v-col
              class="px-0"
              cols="12"
            >
              <cw-checkbox
                id="isWarrantor"
                v-model="form.isWarrantor"
                rules="required"
              >
                <template #label>
                  <span data-test>
                    {{ isWarrantorLabel }}
                  </span>
                </template>
              </cw-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';
import * as items from '@fi/pages/steps/data/customerQuestions';

export default {
  name: 'CwBusinessLoanApplicant',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
    'cw-select': () => import('@shared/components/inputs/CwSelect'),
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    form: {
      assetsTotal: null,
      education: null,
      email: null,
      isWarrantor: 'off',
      personalIncome: null,
      phone: null,
    },
    populationData: {},
    items,
  }),

  computed: {
    formattedPhoneNumber() {
      return `+358${this.form.phone.replace(/\s/g, '').replace(/^\+358|^0/, '')}`;
    },
    /**
     * Gettext translations
     */
    emailHint: vm => vm.$gettext('Email in the form of name@example.com'),
    emailLabel: vm => vm.$gettext('Email'),
    phoneHint: vm => vm.$gettext('Phone number in the form of 0401234567'),
    phoneLabel: vm => vm.$gettext('Phone number'),
    assetsTotalHint: vm => vm.$gettext('Total amount of personal property. A property is a car, shares, apartment, boat, summer cottage, or other valuable property.'),
    assetsTotalLabel: vm => vm.$gettext('Property'),
    educationHint: vm => vm.$gettext('The latest degree'),
    educationLabel: vm => vm.$gettext('Education'),
    personalIncomeHint: vm => vm.$gettext('Personal income'),
    personalIncomeLabel: vm => vm.$gettext('Income'),
    isWarrantorLabel: vm => vm.$gettext('I agree to act as guarantor of the loan'),
  },

  async mounted() {
    const { hashId } = this.$route.params;
    this.populationData = await this.getPopulationData({ hashId });
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
      getPopulationData: 'application/getPopulationData',
    }),

    async submit() {
      try {
        this.$wait.start('SUBMIT_FORM');
        const params = {
          formParams: {
            ...this.form,
            phone: this.formattedPhoneNumber,
          },
          routineParams: {
            uri: 'executeRoutine',
            routine: 'Submit Applicant Information',
          },
        };

        await this.submitForm(params);
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },

    async validateForm() {
      const valid = await this.$refs.observer.validate();
      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationClick' } });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationSubmit' } });
      await this.submit();
    },
  },
};
</script>
